import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output, ViewChild, signal } from '@angular/core';
import { BlockUIModule } from 'ng-block-ui';
import { ComponentBase } from '../../../component-base';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { FileType } from '../../../enums/file-type';
import { Folders } from '../../../enums/folder';
import { InsurancePreassessmentQuestionComponent } from './insurance-preassessment-question/insurance-preassessment-question.component';
import { JourneyClientService } from '../../../services/journey-client.service';
import { LeftContentComponent } from '../../../shared/left-content/left-content.component';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NotesViewerComponent } from '../../../shared/notes-viewer/notes-viewer.component';
import { Pages } from '../../../enums/page';
import { PdfContent } from '../../../entities/pdf-content';
import { PdfExportComponent } from '../../../shared/pdf-export/pdf-export.component';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'your-health',
  standalone: true,
  imports: [
    LeftContentComponent,
    MatProgressSpinner,
    BlockUIModule,
    MatTabsModule,
    NgScrollbarModule,
    InsurancePreassessmentQuestionComponent,
    PdfExportComponent
  ],
  templateUrl: './your-health.component.html',
  styleUrl: './your-health.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YourHealthComponent extends ComponentBase implements OnInit {
  constructor(
    private injector: Injector,
    public journeyClientService: JourneyClientService,
    private router: Router,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(Pages.YourHealth, injector, 'your-health');
  }

  pagesEnum = Pages;
  isJoint = signal(false);
  @Output() readonly showHeaderAndNavigation = new EventEmitter<boolean>();
  @ViewChild("primary", { static: false }) primary!: InsurancePreassessmentQuestionComponent;
  @ViewChild("spouse", { static: false }) spouse!: InsurancePreassessmentQuestionComponent;
  @ViewChild("single", { static: false }) single!: InsurancePreassessmentQuestionComponent;
  @ViewChild(PdfExportComponent, { static: false }) pdfExportComponent!: PdfExportComponent;
  pdfContent = signal<PdfContent>({
    pdfSections: [],
    title: ""
  });

  ngOnInit(): void {
    this.showHeaderAndNavigation.emit(false);

    if (this.journeyClientService.primaryJourneyClient() && this.journeyClientService.spouseJourneyClient()) {
      this.isJoint.set(true);
    }
  }

  async onBackClicked(): Promise<void> {
    const saveSuccessful = await this.save();
    if (!saveSuccessful) {
      return;
    }

    await this.router.navigate(["/expect-next"]);
    this.showHeaderAndNavigation.emit(true);
  }

  async onFinishClicked(): Promise<void> {
    this.blockUI.start();
    const saveSuccessful = await this.save();
    if (!saveSuccessful) {
      this.blockUI.stop();
      return;
    }

    if (this.isJoint()) {
      if (!await this.printQuestions(this.primary, "Primary", this.journeyClientService.getNonNullablePrimaryClientID())) {
        this.blockUI.stop();
        return;
      }
      if (!await this.printQuestions(this.spouse, "Spouse", this.journeyClientService.getNonNullableSpouseClientID())) {
        this.blockUI.stop();
        return;
      }
    } else {
      if (!await this.printQuestions(this.single, "Primary", this.journeyClientService.getNonNullablePrimaryClientID())) {
        this.blockUI.stop();
        return;
      }
    }

    await this.router.navigate(["/expect-next"]);
    this.showHeaderAndNavigation.emit(true);
    this.blockUI.stop();
  }

  override async save(): Promise<boolean> {
    if (this.isJoint()) {
      if (!this.validateForm(this.primary, this.spouse)) {
        return false;

      }
      await this.primary.save();
      await this.spouse.save();
    } else {
      if (!this.validateForm(this.single)) {
        return false;
      }
      await this.single.save();
    }

    return super.save();
  }

  private validateForm(...components: InsurancePreassessmentQuestionComponent[]): boolean {
    for (const component of components) {
      if (!component.personalHealthFormComponent.isValid()) {
        this.showValidationErrorMessage("Personal Health", component)
        return false;
      }

      if (!component.medicalHistoryFormComponent.isValid()) {
        this.showValidationErrorMessage("Medical History", component)
        return false;
      }

      if (!component.occupationsAndPastimesFormComponent.isValid()) {
        this.showValidationErrorMessage("Occupation and Pastimes", component)
        return false;
      }
    }

    return true;
  }

  private showValidationErrorMessage(formName: string, component: InsurancePreassessmentQuestionComponent) {
    const ownerName = component.journeyClient().firstName ?? "<Name could not be determined.>";

    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Validation errors",
        message: `The ${formName} form for ${ownerName} is invalid. The save cannot proceed until the validation errors are fixed. Please fix the validation errors and try again.`,
        mainButtonText: "Ok",
        showIcon: true,
      },
    });

  }

  onNotesClicked(): void {
    this.dialog.open(NotesViewerComponent, {
      height: "522px",
      width: "961px",
      data: { pageId: Pages.YourHealth }
    });
  }

  private async printQuestions(insurancePreassessmentQuestionComponent: InsurancePreassessmentQuestionComponent, title: string, clientID: string): Promise<boolean> {
    const fileName = `Insurance pre-assessment questions ${moment().format("DD-MM-yyyy HHmmss")}.pdf`;
    const pdfSections = await insurancePreassessmentQuestionComponent.getPdfSections();
    this.pdfContent.set({
      pdfSections,
      title
    });

    this.changeDetectorRef.detectChanges();

    const result = await this.pdfExportComponent.printAndSave(FileType.InsurancePreassessmentQuestions, clientID, fileName, Folders.InsuranceNeedsAssessment, false);

    if (!result.wasSuccessful) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: "Error generating PDF",
          message: `We were unable to generating the Insurance pre-assessment questions for ${title}. If the issue persists please contact the admin.`,
          mainButtonText: "Ok",
          altButtonText: "Cancel",
          showAltButton: false,
          showIcon: true
        },
      });

      await firstValueFrom(dialogRef.afterClosed());

      return false;
    }

    return true;
  }
}