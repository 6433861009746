import { ChangeDetectionStrategy, Component, OnInit, ViewChild, input } from '@angular/core';
import { PdfSection, TableHeader, TableRow } from '../../../../entities/pdf-content';
import { getBmi, getYesNoString, isNullOrUndefined } from '../../../../shared/util';
import { EmploymentDetailsService } from '../../../../services/employment-details.service';
import { FormComponent } from '../../../../shared/form/form.component';
import { FormDataTypes } from '../../../../enums/form-data-type';
import { FormFieldTypes } from '../../../../enums/form-field-type';
import { FormItem } from '../../../../entities/form-item';
import { FormSection } from '../../../../entities/form-section';
import { InsurancePreassessmentQuestion } from '../../../../entities/insurance-preassessment-question';
import { InsurancePreassessmentQuestionService } from '../../../../services/insurance-preassessment-question.service';
import { InsurancePreassessmentQuestions } from '../../../../enums/insurance-preassessment-question';
import { JourneyClient } from '../../../../entities/journey-client';
import { JourneyPaymentService } from '../../../../services/journey-payment-service';
import { JourneyService } from '../../../../services/journey.service';
import { MasterDataService } from '../../../../services/master-data.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { OwnerType } from '../../../../entities/owner-type';
import { PaymentTypes } from '../../../../enums/payment-type';
import { PdfSectionTypes } from '../../../../enums/pdf-section-type';
import { Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';
import { percentValidator } from '../../../../shared/constants';

@Component({
  selector: 'insurance-preassessment-question',
  standalone: true,
  imports: [MatExpansionModule, FormComponent],
  templateUrl: './insurance-preassessment-question.component.html',
  styleUrl: './insurance-preassessment-question.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsurancePreassessmentQuestionComponent implements OnInit {
  constructor(
    private insurancePreassessmentQuestionService: InsurancePreassessmentQuestionService,
    private employmentDetailsService: EmploymentDetailsService,
    private journeyService: JourneyService,
    private journeyPaymentService: JourneyPaymentService
  ) {

  }

  journeyClient = input.required<JourneyClient>();
  owner = input.required<OwnerType>();
  personalHealthFormSection: FormSection[] = [];
  medicalHistoryFormSection: FormSection[] = [];
  occupationsAndPastimesFormSection: FormSection[] = [];
  @ViewChild("personalHealth", { static: false }) personalHealthFormComponent!: FormComponent;
  @ViewChild("medicalHistory", { static: false }) medicalHistoryFormComponent!: FormComponent;
  @ViewChild("occupationsAndPastimes", { static: false }) occupationsAndPastimesFormComponent!: FormComponent;
  private readonly maxHeight = 999;
  private readonly maxWeight = 999;
  private readonly minHeight = 0;
  private readonly minWeight = 0;

  ngOnInit(): void {
    const weight = this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.Weight, this.journeyClient(), "number");
    const height = this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.Height, this.journeyClient(), "number")

    const bmi = weight && height ? getBmi(weight, height) : null;

    this.personalHealthFormSection.push({
      title: null,
      name: "personalHealth",
      formItems: [
        {
          label: "Health",
          formDataType: FormDataTypes.Health,
          formFieldType: FormFieldTypes.Dropdown,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.Health, this.journeyClient(), "number"),
          collection: MasterDataService.getHealthTypes()
        },
        {
          label: "Are you currently pregnant?",
          formDataType: FormDataTypes.AreYouCurrentlyPregnant,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.AreYouCurrentlyPregnant, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Due date and any abnormal test results for current and/or previous pregnancies",
            formDataType: FormDataTypes.DueDateAndAnyAbnormalTestResults,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.AreYouCurrentlyPregnant, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.AreYouCurrentlyPregnant,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Height (cm)",
          formDataType: FormDataTypes.Height,
          formFieldType: FormFieldTypes.Number,
          validators: [Validators.min(this.minHeight), Validators.max(this.maxHeight)],
          value: height,
          valueChangeCallback: this.calculateBmi()
        },
        {
          label: "Weight (kg)",
          formDataType: FormDataTypes.Weight,
          formFieldType: FormFieldTypes.Number,
          validators: [Validators.min(this.minWeight), Validators.max(this.maxWeight)],
          value: weight,
          valueChangeCallback: this.calculateBmi()
        },
        {
          label: "BMI",
          formDataType: FormDataTypes.BMI,
          formFieldType: FormFieldTypes.Readonly,
          validators: [],
          value: bmi
        },
        {
          label: "Have you gained/lost more than 5kg in the last 12 months?",
          formDataType: FormDataTypes.HaveYouGainedLostMoreThanFiveKG,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.HaveYouGainedLostMoreThanFiveKG, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.HaveYouGainedLostMoreThanFiveKGComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.HaveYouGainedLostMoreThanFiveKG, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.HaveYouGainedLostMoreThanFiveKG,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Have you smoked any substances in the last 12 months?",
          formDataType: FormDataTypes.HaveYouSmokedAnySubstancesLastTwelveMonths,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.HaveYouSmokedAnySubstancesLastTwelveMonths, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.HaveYouSmokedAnySubstancesLastTwelveMonthsComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.HaveYouSmokedAnySubstancesLastTwelveMonths, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.HaveYouSmokedAnySubstancesLastTwelveMonths,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "How many standard drinks do you drink per week?",
          formDataType: FormDataTypes.HowManyStandardDrinksPerWeek,
          formFieldType: FormFieldTypes.Number,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.HowManyStandardDrinksPerWeek, this.journeyClient(), "number")
        }
      ]
    });

    this.medicalHistoryFormSection.push({
      title: null,
      name: "medicalHistory",
      formItems: [
        {
          label: "Are you currently on any medication?",
          formDataType: FormDataTypes.AreYouCurrentlyOnAnyMedication,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.AreYouCurrentlyOnAnyMedication, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "What type, dosage, how long?",
            formDataType: FormDataTypes.WhatTypeDosageHowLong,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.AreYouCurrentlyOnAnyMedication, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.AreYouCurrentlyOnAnyMedication,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Have you had any surgeries, medical tests/investigations (i.e. x-rays, ultrasounds) been referred to a specialist or been a patient of a hospital?",
          formDataType: FormDataTypes.HaveYouHadAnySurgeries,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.HaveYouHadAnySurgeries, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Doctor, GP/Specialist/Hospital visit, date of most recent visit, reason for visit, results?",
            formDataType: FormDataTypes.DoctorVisitResults,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.HaveYouHadAnySurgeries, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.HaveYouHadAnySurgeries,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Do any of your immediate family have any significant medical condition or hereditary disease?",
          formDataType: FormDataTypes.ImmediateFamilySignificantMedicalCondition,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.ImmediateFamilySignificantMedicalCondition, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Relationship , name, medical condition, details, age diagnosed, age at death?",
            formDataType: FormDataTypes.RelationshipNameMedicalCondition,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.ImmediateFamilySignificantMedicalCondition, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.ImmediateFamilySignificantMedicalCondition,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Have you ever had symptoms, sought advice for, or been diagnosed with any medical condition?",
          formDataType: FormDataTypes.AnyMedicalCondition,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.AnyMedicalCondition, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Medical condition, Details, Diagnosed?",
            formDataType: FormDataTypes.MedicalConditionDetailsDiagnosed,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.AnyMedicalCondition, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.AnyMedicalCondition,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Have you ever made a claim for personal insurance, workers compensation or sickness/disability support?",
          formDataType: FormDataTypes.ClaimForPersonalInsurance,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.ClaimForPersonalInsurance, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.ClaimForPersonalInsuranceComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.ClaimForPersonalInsurance, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.ClaimForPersonalInsurance,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Have you in the last 5 years taken any drug/tablet, or medication (including illicit drugs) not already advised?",
          formDataType: FormDataTypes.TakenAnyDrugsInFiveYear,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.TakenAnyDrugsInFiveYear, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Medication, dosage required, prescribing doctor, last prescription?",
            formDataType: FormDataTypes.MedicationDosageRequired,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.TakenAnyDrugsInFiveYear, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.TakenAnyDrugsInFiveYear,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Have you ever applied for any personal insurance and had your cover declined, postponed,  or offered special terms (i.e. exclusions or loadings)?",
          formDataType: FormDataTypes.DeclinedPostponedInsurance,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.DeclinedPostponedInsurance, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.DeclinedPostponedInsuranceComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.DeclinedPostponedInsurance, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.DeclinedPostponedInsurance,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        }
      ]
    });

    this.occupationsAndPastimesFormSection.push({
      title: null,
      name: "occupationsAndPastimes",
      formItems: [
        {
          label: "Do you have any professional or trade qualifications related to your work?",
          formDataType: FormDataTypes.ProfessionalTradeQualifications,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.ProfessionalTradeQualifications, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.ProfessionalTradeQualificationsComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.ProfessionalTradeQualifications, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.ProfessionalTradeQualifications,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "% of work which is office/admin based?",
          formDataType: FormDataTypes.PercentageWorkOfficeAdminBased,
          formFieldType: FormFieldTypes.Number,
          validators: [...percentValidator],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.PercentageWorkOfficeAdminBased, this.journeyClient(), "number")
        },
        {
          label: "% of work which requires manual labour",
          formDataType: FormDataTypes.PercentageWorkRequiresManualLabour,
          formFieldType: FormFieldTypes.Number,
          validators: [...percentValidator],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.PercentageWorkRequiresManualLabour, this.journeyClient(), "number")
        },
        {
          label: "% of work which is driving",
          formDataType: FormDataTypes.PercentageWorkWhichDriving,
          formFieldType: FormFieldTypes.Number,
          validators: [...percentValidator],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.PercentageWorkWhichDriving, this.journeyClient(), "number")
        },
        {
          label: "Does your job involve you to work underground/water, do any heavy lifting, operate heavy machinery or work with any hazardous materials (i.e., in a lab, on site or mining)?",
          formDataType: FormDataTypes.WorkUnderground,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.WorkUnderground, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.WorkUndergroundComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.WorkUnderground, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.WorkUnderground,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Have you been in your current role for less than 3 years or do you have any intentions to change your role?",
          formDataType: FormDataTypes.JobLength,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.JobLength, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.JobLengthComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.JobLength, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.JobLength,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        },
        {
          label: "Do you participate in, or have intentions to participate, in any contact/competitive sports (i.e. football, martial arts, horse riding) or recreational activities that involve motorised sports, heights or going underground or under water?",
          formDataType: FormDataTypes.ParticipateContactCompetitiveSports,
          formFieldType: FormFieldTypes.ToggleWithComment,
          validators: [],
          value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionValue(InsurancePreassessmentQuestions.ParticipateContactCompetitiveSports, this.journeyClient(), "boolean"),
          includeComment: true,
          commentBoxFormItem: {
            label: "Comments",
            formDataType: FormDataTypes.ParticipateContactCompetitiveSportsComment,
            formFieldType: FormFieldTypes.TextArea,
            validators: [],
            value: this.insurancePreassessmentQuestionService.getInsurancePreassessmentQuestionComment(InsurancePreassessmentQuestions.ParticipateContactCompetitiveSports, this.journeyClient()),
            visibilityFormItemDataTypeID: FormDataTypes.ParticipateContactCompetitiveSports,
            visibilityFunction(value: string): boolean {
              return value === "true";
            }
          }
        }
      ]
    });
  }

  async save(): Promise<void> {
    const insurancePreassessmentQuestions: InsurancePreassessmentQuestion[] = [
      ...this.getPersonalHealthQuestion(),
      ...this.getMedicalHistoryQuestions(),
      ...this.getOccupationAndPastimeQuestions()
    ];

    await this.insurancePreassessmentQuestionService.updateInsurancePreassessmentQuestion(...insurancePreassessmentQuestions)
  }

  async getPdfSections(): Promise<PdfSection[]> {
    const tableHeaders: TableHeader[] = [
      {
        name: "Question",
        width: "50%"
      },
      {
        name: "Answer",
        width: "50%"
      }
    ];

    return [
      await this.getPDFBasicDetailsPDFSection(),
      {
        pdfSectionType: PdfSectionTypes.Table,
        title: "Personal Health",
        content: {
          tableHeaders,
          tableRows: this.getPersonalHealthPDFTableRows()
        },
        breakLine: true
      },
      {
        pdfSectionType: PdfSectionTypes.Table,
        title: "Medical History",
        content: {
          tableHeaders,
          tableRows: this.getMedicalHistoryPDFTableRows()
        },
        breakLine: true
      },
      {
        pdfSectionType: PdfSectionTypes.Table,
        title: "Occupations and Pastimes",
        content: {
          tableHeaders,
          tableRows: this.getOccupationAndPastimePDFTableRows()
        },
        breakLine: true
      }
    ];
  }

  private getPersonalHealthQuestion(): InsurancePreassessmentQuestion[] {
    const insurancePreassessmentQuestions: InsurancePreassessmentQuestion[] = [];

    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.Health, FormDataTypes.Health));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.AreYouCurrentlyPregnant, FormDataTypes.AreYouCurrentlyPregnant, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.Height, FormDataTypes.Height));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.Weight, FormDataTypes.Weight));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.BMI, FormDataTypes.BMI));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.HaveYouGainedLostMoreThanFiveKG, FormDataTypes.HaveYouGainedLostMoreThanFiveKG, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.HaveYouSmokedAnySubstancesLastTwelveMonths, FormDataTypes.HaveYouSmokedAnySubstancesLastTwelveMonths, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.personalHealthFormComponent, InsurancePreassessmentQuestions.HowManyStandardDrinksPerWeek, FormDataTypes.HowManyStandardDrinksPerWeek));

    return insurancePreassessmentQuestions;
  }

  private getMedicalHistoryQuestions(): InsurancePreassessmentQuestion[] {
    const insurancePreassessmentQuestions: InsurancePreassessmentQuestion[] = [];


    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.medicalHistoryFormComponent, InsurancePreassessmentQuestions.AreYouCurrentlyOnAnyMedication, FormDataTypes.AreYouCurrentlyOnAnyMedication, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.medicalHistoryFormComponent, InsurancePreassessmentQuestions.HaveYouHadAnySurgeries, FormDataTypes.HaveYouHadAnySurgeries, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.medicalHistoryFormComponent, InsurancePreassessmentQuestions.ImmediateFamilySignificantMedicalCondition, FormDataTypes.ImmediateFamilySignificantMedicalCondition, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.medicalHistoryFormComponent, InsurancePreassessmentQuestions.AnyMedicalCondition, FormDataTypes.AnyMedicalCondition, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.medicalHistoryFormComponent, InsurancePreassessmentQuestions.ClaimForPersonalInsurance, FormDataTypes.ClaimForPersonalInsurance, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.medicalHistoryFormComponent, InsurancePreassessmentQuestions.TakenAnyDrugsInFiveYear, FormDataTypes.TakenAnyDrugsInFiveYear, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.medicalHistoryFormComponent, InsurancePreassessmentQuestions.DeclinedPostponedInsurance, FormDataTypes.DeclinedPostponedInsurance, true));

    return insurancePreassessmentQuestions;
  }

  private getOccupationAndPastimeQuestions(): InsurancePreassessmentQuestion[] {
    const insurancePreassessmentQuestions: InsurancePreassessmentQuestion[] = [];

    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.occupationsAndPastimesFormComponent, InsurancePreassessmentQuestions.ProfessionalTradeQualifications, FormDataTypes.ProfessionalTradeQualifications, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.occupationsAndPastimesFormComponent, InsurancePreassessmentQuestions.PercentageWorkOfficeAdminBased, FormDataTypes.PercentageWorkOfficeAdminBased));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.occupationsAndPastimesFormComponent, InsurancePreassessmentQuestions.PercentageWorkRequiresManualLabour, FormDataTypes.PercentageWorkRequiresManualLabour));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.occupationsAndPastimesFormComponent, InsurancePreassessmentQuestions.PercentageWorkWhichDriving, FormDataTypes.PercentageWorkWhichDriving));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.occupationsAndPastimesFormComponent, InsurancePreassessmentQuestions.WorkUnderground, FormDataTypes.WorkUnderground, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.occupationsAndPastimesFormComponent, InsurancePreassessmentQuestions.JobLength, FormDataTypes.JobLength, true));
    insurancePreassessmentQuestions.push(this.getInsurancePreassessmentQuestion(this.occupationsAndPastimesFormComponent, InsurancePreassessmentQuestions.ParticipateContactCompetitiveSports, FormDataTypes.ParticipateContactCompetitiveSports, true));

    return insurancePreassessmentQuestions;
  }

  private getInsurancePreassessmentQuestion(formComponent: FormComponent, insurancePreassessmentQuestionID: number, formDataTypeID: number, isBoolean = false): InsurancePreassessmentQuestion {
    const formItem = formComponent.formSections[0].formItems.find(x => x.formDataType === formDataTypeID);
    // For boolean, we convert null/undefined to false as we have no way of distinguishing between null/undefined and false.
    const value = isBoolean ? this.insurancePreassessmentQuestionService.booleanToString(formComponent.getFormItemValue(formDataTypeID).getBooleanOptional(true), true)
      : isNullOrUndefined(formItem?.formControl?.value) ? null : String(formItem.formControl.value)

    const insurancePreassessmentQuestion = this.insurancePreassessmentQuestionService.createJourneyExcludedAdviceArea(insurancePreassessmentQuestionID,
      this.journeyClient().journeyClientID, value);

    if (formItem?.commentBoxFormItem) {
      insurancePreassessmentQuestion.comment = String(formItem.commentBoxFormItem.formControl?.value);
    }

    return insurancePreassessmentQuestion;
  }

  private getPersonalHealthPDFTableRows(): TableRow[] {
    const tableRows: TableRow[] = [];

    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.Health));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.AreYouCurrentlyPregnant));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.Height));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.Weight));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.BMI));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.HaveYouGainedLostMoreThanFiveKG));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.HaveYouSmokedAnySubstancesLastTwelveMonths));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.personalHealthFormComponent, FormDataTypes.HowManyStandardDrinksPerWeek));


    return tableRows;
  }

  private getMedicalHistoryPDFTableRows(): TableRow[] {
    const tableRows: TableRow[] = [];

    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.medicalHistoryFormComponent, FormDataTypes.AreYouCurrentlyOnAnyMedication));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.medicalHistoryFormComponent, FormDataTypes.HaveYouHadAnySurgeries));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.medicalHistoryFormComponent, FormDataTypes.ImmediateFamilySignificantMedicalCondition));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.medicalHistoryFormComponent, FormDataTypes.AnyMedicalCondition));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.medicalHistoryFormComponent, FormDataTypes.ClaimForPersonalInsurance));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.medicalHistoryFormComponent, FormDataTypes.TakenAnyDrugsInFiveYear));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.medicalHistoryFormComponent, FormDataTypes.DeclinedPostponedInsurance));

    return tableRows;
  }

  private getOccupationAndPastimePDFTableRows(): TableRow[] {
    const tableRows: TableRow[] = [];

    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.occupationsAndPastimesFormComponent, FormDataTypes.ProfessionalTradeQualifications));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.occupationsAndPastimesFormComponent, FormDataTypes.PercentageWorkOfficeAdminBased));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.occupationsAndPastimesFormComponent, FormDataTypes.PercentageWorkRequiresManualLabour));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.occupationsAndPastimesFormComponent, FormDataTypes.PercentageWorkWhichDriving));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.occupationsAndPastimesFormComponent, FormDataTypes.WorkUnderground));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.occupationsAndPastimesFormComponent, FormDataTypes.JobLength));
    tableRows.push(...InsurancePreassessmentQuestionComponent.getPdfTableRow(this.occupationsAndPastimesFormComponent, FormDataTypes.ParticipateContactCompetitiveSports));

    return tableRows;
  }

  private static getPdfTableRow(formComponent: FormComponent, formDataTypeID: number): TableRow[] {
    const formItem = formComponent.formSections[0].formItems.find(x => x.formDataType === formDataTypeID);
    if (!formItem) return [];

    const rows: TableRow[] = [];
    rows.push({
      columns: [
        {
          content: formItem.label
        },
        {
          content: InsurancePreassessmentQuestionComponent.getValue(formItem),
          isItalic: true
        }
      ]
    });

    if (!formItem.commentBoxFormItem) return rows;

    rows.push({
      columns: [
        {
          content: formItem.commentBoxFormItem.label
        },
        {
          content: formItem.commentBoxFormItem.formControl?.value ? String(formItem.commentBoxFormItem.formControl.value) : "-",
          isItalic: true
        }
      ]
    });

    return rows;
  }

  private static getValue(formItem: FormItem): string {
    let value: string | undefined;

    switch (formItem.formFieldType) {
      case FormFieldTypes.Dropdown:
        value = formItem.collection?.find(x => x.iD === Number(formItem.formControl?.value))?.name;
        break;
      case FormFieldTypes.Checkbox:
      case FormFieldTypes.ToggleWithComment:
      case FormFieldTypes.Toggle:
        value = getYesNoString(String(formItem.formControl?.value));
        break;
      default:
        value = String(formItem.formControl?.value)
        break;
    }

    if (isNullOrUndefined(value) || value === "null") {
      return "-";
    }

    return value;
  }

  private calculateBmi() {
    return () => {

      const bmiFormItem = this.personalHealthFormSection[0].formItems.find(x => x.formDataType === FormDataTypes.BMI.valueOf());
      if (!bmiFormItem) {
        return;
      }

      const height = this.personalHealthFormComponent.getFormItemValue(FormDataTypes.Height).getNumberOptional();
      const weight = this.personalHealthFormComponent.getFormItemValue(FormDataTypes.Weight).getNumberOptional();
      if (height && weight) {
        bmiFormItem.formControl?.setValue(getBmi(weight, height));
      } else {
        bmiFormItem.formControl?.setValue(null);
      }
    }
  }

  private async getPDFBasicDetailsPDFSection(): Promise<PdfSection> {
    const employmentDetailRecords = await firstValueFrom(this.employmentDetailsService.getEmploymentDetails(this.journeyService.getNonNullableJourney().journeyID));
    const salary = this.journeyPaymentService.items().find(x=> x.owner === this.owner() && x.typeId === Number(PaymentTypes.Salary));

    return {
      pdfSectionType: PdfSectionTypes.Table,
      title: "Basic Details",
      content: {
        tableHeaders: [],
        tableRows: [
          {
            columns: [
              {
                content: "Current Age"
              },
              {
                content: moment().diff(moment(this.journeyClient().birthDate), "years")
              }
            ]
          },
          {
            columns: [
              {
                content: "Gender"
              },
              {
                content: this.journeyClient().gender ?? ""
              }
            ]
          },
          {
            columns: [
              {
                content: "Occupation"
              },
              {
                content: employmentDetailRecords.find(x => x.journeyClientID === this.journeyClient().journeyClientID)?.occupation ?? ""
              }
            ]
          },
          {
            columns: [
              {
                content: "Income (p.a.)"
              },
              {
                content: salary ? `$${JourneyPaymentService.getAnnualPaymentAmount(salary)}` : ""
              }
            ]
          }
        ]
      },
      breakLine: true
    };
  }
}
