<block-ui>
    <div class="section">
        @defer {
        <left-content [selector]="'your-health'">
        </left-content>
        <div class="content">
            <div class="container">
                @if(isJoint()) {
                <div class="tabContainer">
                    <mat-tab-group>
                        <mat-tab [label]="journeyClientService.getClientName('primary')">
                            <div class="accordionJointClient">
                                <ng-scrollbar>
                                    <insurance-preassessment-question #primary owner="primary" 
                                        [journeyClient]="journeyClientService.primaryJourneyClient()!" />
                                </ng-scrollbar>
                            </div>
                        </mat-tab>
                        <mat-tab [label]="journeyClientService.getClientName('spouse')">
                            <div class="accordionJointClient">
                                <ng-scrollbar>
                                    <insurance-preassessment-question #spouse owner="spouse"
                                        [journeyClient]="journeyClientService.spouseJourneyClient()!" />
                                </ng-scrollbar>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                }
                @else {

                <div class="accordionSingleClient">
                    <ng-scrollbar>
                        <insurance-preassessment-question #single owner="primary" 
                            [journeyClient]="journeyClientService.primaryJourneyClient()!" />
                    </ng-scrollbar>
                </div>
                }

                <div class="actions">
                    <div class="actionButtons">
                        <button mat-button class="altButton" (click)="onBackClicked()">Back</button>
                        <button mat-button class="mainButton" (click)="onFinishClicked()">Complete</button>
                    </div>
                    <div class="notes" (click)="onNotesClicked()">
                        <button mat-fab class="notesButton"><img src="assets/notes-icon.svg" alt=""
                                class="icon" /></button>
                    </div>
                </div>
            </div>
        </div>
        } @placeholder {
        <mat-spinner></mat-spinner>
        }
    </div>
</block-ui>
<div class="hidePDFSection">
    <pdf-export [content]="pdfContent()"></pdf-export>
</div>