<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>Personal Health</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="contentContainer">
            <form #personalHealth [formSections]="personalHealthFormSection"></form>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>Medical History</mat-panel-title>
        </mat-expansion-panel-header>
        <form #medicalHistory [formSections]="medicalHistoryFormSection"></form>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>Occupations and Pastimes</mat-panel-title>
        </mat-expansion-panel-header>
        <form #occupationsAndPastimes [formSections]="occupationsAndPastimesFormSection"></form>
    </mat-expansion-panel>    
</mat-accordion>
